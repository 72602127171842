<script setup lang="ts">
import {toRef} from 'vue';
import {useField} from 'vee-validate';

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  customErrorMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  field: {
    type: Object
  }
});

const name = toRef(props, 'name');

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

const selected = ref(props?.field?.options?.find((option: { label: string, value: string }) => option.value === inputValue.value));

const open = ref(false);

const selectVal = (val: object) => {
  selected.value = val;
  handleChange(val.value);
  open.value = false;
}
</script>

<template>
  <div
      :class="{ 'has-error': !!errorMessage, success: meta.valid }"
      class="custom-select"
  >
    <label :for="name">{{ label }}</label>
    <select
        :name="name"
        :id="name"
        :type="type"
        :value="inputValue"
        :placeholder="placeholder"
        @input="handleChange"
        @blur="handleBlur"
        @select="handleChange"
        @change="handleChange"
    >
      <template v-for="option in field.options" :key="option.value">
        <option :selected="option.value === inputValue" :value="option.value"> {{ option.label }}</option>
      </template>
    </select>
    <div class="custom-select-wrapper" :class="{'_active': open}">
      <div class="selected" @click="open = !open">{{ selected.label }}</div>
      <div class="select-items" v-if="open">
        <template v-for="option in field.options" :key="option.value">
          <div class="select-item" v-if="option.value != selected.value" @click="selectVal(option)"> {{ option.label }}</div>
        </template>
      </div>
    </div>

    <p class="help-message" v-show="errorMessage">
      {{ customErrorMessage }}
    </p>
  </div>
</template>

<style lang="scss">
  @import "assets/css/source/components/custom_select";
</style>